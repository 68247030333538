import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import ApplicationByJobCountList from "./ApplicationByJobCountList";
import RefreshButton from "../../common/widgets/RefreshButton";
import "./ApplicationCountByJobBlock.scss";

function ApplicationCountByJobBlock() {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "ApplicationCountByJobBlock");

  return (
    <div className="ApplicationCountByJobBlock">
      <Row>
        <Col>
          <h1>{t("admin:admin_application_count_by_job_page_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <RefreshButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <ApplicationByJobCountList />
        </Col>
      </Row>
    </div>
  );
}

export default ApplicationCountByJobBlock;
