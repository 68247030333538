import React from "react";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./Footer.scss";
import useTranslationFooter from "./useTranslationFooter";
import { SocialIcon } from "react-social-icons";
import { FooterItem, LanguageFooterItem } from "./FooterComponents";
import {
  LINKEDIN_URL,
  FACEBOOK_URL,
  CONTACT_US,
  PRIVACY,
  CAREERS,
  TERMS,
  ABOUTUS,
  PRICING
} from "../../constants/routes";
import RouterUtils from "../../utils/RouterUtils";


export default function Footer() {
  const { t, loading: tLoading } = useTranslationFooter();

  // Don't use useShowLoader because there is no loader to display over this component; just wait until there is something to display.
  if (tLoading) {
    return null;
  }

  const onClickLink = (url) => {
    RouterUtils.openExternalUrl(url);
  };

  return (
    <>
      <Nav className="Footer basepage-fade-in">
        <Container className="justify-content-md-center">
          <Row>
            <Col className="FooterItemGroup">
              <p className="FooterHeaderItem">
                {t("footer:footer_item_language_msg")}
              </p>
              <Row>
                <Col>
                  <LanguageFooterItem className="FooterItem" to="#">
                    {t("footer:footer_item_language_msg")}
                  </LanguageFooterItem>
                </Col>
              </Row>
            </Col>
            <Col className="FooterItemGroup">
              <p className="FooterHeaderItem">
                {t("footer:footer_item_company_msg")}
              </p>
              <Nav.Item>
                <FooterItem className="FooterItem" onClick={() => onClickLink(ABOUTUS)}>
                  {t("footer:footer_item_about_us_msg")}
                </FooterItem>
              </Nav.Item>
              <Nav.Item>
                <FooterItem className="FooterItem" onClick={() => onClickLink(PRICING)}>
                  {t("footer:footer_item_pricing_msg")}
                </FooterItem>
              </Nav.Item>
              <Nav.Item>
                <FooterItem className="FooterItem" onClick={() => onClickLink(PRIVACY)}>
                  {t("footer:footer_item_privacy_policy")}
                </FooterItem>
              </Nav.Item>
              <Nav.Item>
                <FooterItem className="FooterItem" onClick={() => onClickLink(TERMS)}>
                  {t("footer:footer_item_terms_and_conditions")}
                </FooterItem>
              </Nav.Item>
              <Nav.Item>
                <FooterItem className="FooterItem" onClick={() => onClickLink(CAREERS)}>
                  {t("footer:footer_item_careers")}
                </FooterItem>
              </Nav.Item>
            </Col>
            <Col className="FooterItemGroup">
              <p className="FooterHeaderItem">
                {t("footer:footer_item_contact_us_msg")}
              </p>
              <Nav.Item>
                <FooterItem className="FooterItem" onClick={() => onClickLink(CONTACT_US)}>
                  {t("footer:footer_item_contact_us_msg")}
                </FooterItem>
              </Nav.Item>
            </Col>
            <Col className="FooterItemGroup">
              <p className="FooterHeaderItem">
                {t("footer:footer_item_social_media_msg")}
              </p>
              <SocialIcon url={LINKEDIN_URL} className="SocialIcon" target="_blank" rel="noopener noreferrer" />
              <SocialIcon url={FACEBOOK_URL} className="SocialIcon" target="_blank" rel="noopener noreferrer" />
            </Col>
          </Row>
        </Container>
      </Nav>
      <Container className="companySignature">
        <Row>
          <Col className="companyDisclaimers">
            <span className="companyDisclaimers">
              {t("footer:footer_cad_prices_msg")}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="companySignature">
            <span className="companySignature">
              {t("footer:footer_company_name_msg")}
            </span>
          </Col>
        </Row>
      </Container>
    </>
  );
}
