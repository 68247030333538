import React from "react";
import AppRouter from "./AppRouter";
import UntranslatedContentError from "../common/error-boundaries/UntranslatedContentError";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary";

// Main App
export default function App() {
  return (
    <ErrorBoundary component={<UntranslatedContentError />}>
      <AppRouter />
    </ErrorBoundary>
  );
}
