import React from "react";
import StringUtils from "../../../utils/StringUtils";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

/**
 * Container to structure the elements of a page: page header and page content.
 * Usage:
 *  <Page>
 *    <Page.Title>Page title </Page.Title>
 *    <Page.Content>...</Page.Content>
 *  </Page>
 *
 *  Use <Page.Title text={...}>Component</> to render the Component as the page title.
 *  The "text" property is used to set the document title and the name of the entry in the browser history.
 *
 *  As a shortcut, use <Page.Title>text</> to automatically wrap the text with <h1>.
 *
 *  To display no title, use <Page.Title text={...}/>
 *
 *  It is important that every <Page> component sets a title, otherwise when pushing to the
 *  history a route that does not set a title, the last title will be re-used. This will result in
 *  repeated titles in the history.
 *
 */
export default class Page extends React.Component {
  static Title(props) {
    const { children, loading, text, className } = props;

    if (!StringUtils.isNullOrEmpty(text))
      return (
        <ExplicitTitle loading={loading} text={text}>
          {children}
        </ExplicitTitle>
      );
    else
      return (
        <ImplicitTitle loading={loading} className={className}>
          {children}
        </ImplicitTitle>
      );
  }
  static Content(props) {
    return (
      <div
        className={"PageContent " + StringUtils.nullToEmpty(props.className)}
      >
        <ScrollToTopOnMount />
        {props.children}
      </div>
    );
  }
  static Container(props) {
    return (
      <div
        className={
          "Page spaced-children " + StringUtils.nullToEmpty(props.className)
        }
      >
        {props.children}
      </div>
    );
  }
  render() {
    return Page.Container(this.props);
  }
}
const setDocumentTitle = (title) => {
  document.title = title + " | Spotterful";
};
/**
 * Display a page title and use it as the page name in the browser history.
 * The page title is rendered by the component passed as a child. The child is responsible for
 * rendering an h1 tag if appropriate.
 * The page title to use in the browser history (text only) is provided as a prop.
 * @param props
 * @constructor
 */
function ExplicitTitle(props) {
  const { loading, children, text } = props;

  React.useEffect(() => {
    if (!StringUtils.isNullOrEmpty(text) && !loading) {
      setDocumentTitle(text);
    }
  }, [loading, text]);

  return children ? children : null;
}
/**
 * Display a page title and set the page title in the browser history.
 * The page title is rendered as an h1 component.
 * The page title to use in the browser history (text only) is extracted automatically from the text content
 * of the component passed as a child.
 * @param props
 * @returns {JSX.Element}
 */
function ImplicitTitle(props) {
  const { loading, className, children } = props;

  // Every time the DOM node referenced by titleRef changes, this callback is called to set the document
  // title to the text content of the <h1> tag (with <ReactComponent> tags evaluated, with <html tags> stripped
  // and with html entities interpreted). In case the title is provided by a translation t(), we want to wait until
  // the translation is resolved before setting the page title (to avoid having multiple entries in the history for
  // the same page). In that case, pass in the loading props.
  const titleRef = React.useCallback(
    (node) => {
      if (node !== null && !loading) {
        setDocumentTitle(node.textContent);
      }
    },
    [loading]
  );
  return (
    <h1 ref={titleRef} className={className}>
      {children}
    </h1>
  );
}
