import React, {useCallback} from "react";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobUpdate from "./useMutationJobUpdate";
import { STEP_ENUM } from "./JobSaveForm";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";
import JobSaveCommissionForm from "./JobSaveCommissionForm.js";

export default function useJobSaveCommissionForm(job, isStepSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { mutate, loading: mutationLoading, errors } = useMutationJobUpdate();

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = useCallback(() => {
    return isValid;
  }, [isValid]);

  const submit = useCallback(() => {
    const commission = getValues().commission;
    return mutate({
      variables: {
        id: job._id,
        commission: parseInt(commission.toString()),
        lastSavedStep: isStepSaved ? undefined : STEP_ENUM.COMMISSION,
      },
    });
  }, [getValues, mutate, job._id, isStepSaved]);

  const formProps = React.useMemo(() => ({
    t,
    loading: tLoading,
    control,
    job
  }), [t, tLoading, control, job]);

  return {
    form: JobSaveCommissionForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
