import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import "./JobDashboardCommissionBlock.scss";
import useTranslationJobDashboard from "../useTranslationJobDashboard";
import PageSubtitle from "../../common/widgets/PageSubtitle";
import useQueryJobGlobalMaxCommission from "../../jobs/view/useQueryJobGlobalMaxCommission";
import useQueryJobGlobalAverageCommission from "../../jobs/view/useQueryJobGlobalAverageCommission";
import JobSimilarJobsCommissionTip from "../../jobs/mutate/JobSimilarJobsCommissionTip";
import JobAverageCommissionTip from "../../jobs/mutate/JobAverageCommissionTip";
import JobMaxCommissionTip from "../../jobs/mutate/JobMaxCommissionTip";
import useQueryJobPublicSearch from "../../jobs/search/useQueryJobPublicSearch";
import WaitForData from "../../common/data-fetching/WaitForData";
import TypeUtils from "../../../utils/TypeUtils";


function JobDashboardCommissionBlock(props) {
  const { job } = props;

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading, "JobDashboardCommissionBlock");

  const commissionTitle = t("job-dashboard:job_dashboard_commission_label");

  // Get max commission
  const {
    data: jobGlobalMaxCommission,
    loading: jobGlobalMaxCommissionLoading,
    errors: jobGlobalMaxCommissionErrors,
  } = useQueryJobGlobalMaxCommission();

  // Get average commission
  const {
    data: jobGlobalAverageCommission,
    loading: jobGlobalAverageCommissionLoading,
    errors: jobGlobalAverageCommissionErrors,
  } = useQueryJobGlobalAverageCommission();

  // Get similar jobs
  // Memoize searchFilters to avoid re-rendering of components that use them on every render of this component
  const searchFilters = React.useMemo(() => ({
    queryText: TypeUtils.arrayRemoveNullOrEmpty([job.title, ...TypeUtils.ensureArray(job.technicalSkills)]).slice(0, 10),
    status: "PUBLISHED",
  }), [job.title, job.technicalSkills]);

  const {
    loading: similarJobsLoading,
    errors: similarJobsErrors,
    data: similarJobs,
  } = useQueryJobPublicSearch(10, searchFilters, "DATE_POSTED_DESC");

  return (
    <div className="JobDashboardCommissionBlock">
      <PageSubtitle colTitle={commissionTitle} />
      <Row>
        <Col className="commission-col">
          {job.commission > 0 && (
            <span className="commission-span">
              {t("job-dashboard:job_dashboard_job_details_commission_amounts", {
                amount: job.commission,
              })}
            </span>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <WaitForData
            loading={
              similarJobsLoading ||
              jobGlobalMaxCommissionLoading ||
              jobGlobalAverageCommissionLoading
            }
            errors={
              similarJobsErrors ||
              jobGlobalMaxCommissionErrors ||
              jobGlobalAverageCommissionErrors
            }
            onLoaded={() => (
              <>
                <Row>
                  <Col>
                    <JobSimilarJobsCommissionTip similarJobs={similarJobs} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <JobAverageCommissionTip
                      jobGlobalAverageCommission={jobGlobalAverageCommission}
                      similarJobs={similarJobs}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <JobMaxCommissionTip
                      jobGlobalMaxCommission={jobGlobalMaxCommission}
                      similarJobs={similarJobs}
                    />
                  </Col>
                </Row>
              </>
            )}
          />
        </Col>
      </Row>
    </div>
  );
}

export default JobDashboardCommissionBlock;
