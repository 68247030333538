import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import "./JobDashboardSummaryBlock.scss";
import useTranslationJobDashboard from "../useTranslationJobDashboard";
import StringUtils from "../../../utils/StringUtils";
import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";
import HiredCandidatesBlock from "./HiredCandidatesBlock";
import RefreshButton from "../../common/widgets/RefreshButton";
import useMutationJobDelete from "../mutate/useMutationJobDelete";
import useFeedbackModal from "../../common/modals/useFeedbackModal";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import { Badge } from "react-bootstrap";
import JobStatusEnum from "../../jobs/JobStatusEnum";
import {getEmploymentTypeLabel} from "../../jobs/EmploymentTypeEnum";
import {getSalaryUnitLabel} from "../../jobs/SalaryUnitEnum";
import TypeUtils from "../../../utils/TypeUtils";
import useReferrer from "../../infra-no-ui/navigation/useReferrer";
import useMutationJobPublish from "../../jobs/mutate/useMutationJobPublish.js";
import useMutationJobUnpublish from "../../jobs/mutate/useMutationJobUnpublish.js";
import ActionButton from "../../common/widgets/ActionButton.js";

function JobDashboardSummaryBlock(props) {
  const { job } = props;
  const {redirectTo} = useReferrer();

  const hasBeenPublished = job.hasBeenPublished;
  const isPublished = job.status === JobStatusEnum.PUBLISHED;
  const isUnpublished = job.status === JobStatusEnum.UNPUBLISHED;
  const isArchived = job.status === JobStatusEnum.ARCHIVED;
  const isDraft = job.status === JobStatusEnum.DRAFT;
  const isPending = job.status === JobStatusEnum.PENDING;

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading);

  const onClickUpdate = (event) => {
    event.preventDefault();
    redirectTo(
      RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_JOB_UPDATE, {
        jobId: job._id,
      })
    );
  };

  const addressLine = TypeUtils.arrayRemoveNullOrEmpty([job.city, job.state, job.country])
    .join(", ");

  const salary = parseInt(job.baseSalaryValue);
  const salaryUnitLabel = getSalaryUnitLabel(t, job.baseSalaryUnitText);
  const salaryLine =
    salary > 0 && !StringUtils.isNullOrEmpty(salaryUnitLabel)
      ? `$ ${salary} ${t(
          "job-dashboard:job_dashboard_salary_per_label"
        )} ${salaryUnitLabel}`
      : "";

  // Delete a draft job
  const {
    mutate: deleteMutation,
    loading: deleteLoading,
    errors: deleteErrors,
  } = useMutationJobDelete();

  // Unpublish a (previously) published job
  const {
    mutate: unpublishMutation,
    loading: unpublishLoading,
    errors: unpublishErrors,
  } = useMutationJobUnpublish();

  const unpublishErrorMsg = useServerErrorFormatter(unpublishErrors);

  const unpublishJob = () => {
    return unpublishMutation({
      variables: {
        id: job._id,
      },
    });
  };

  const { launch: launchUnpublish, FeedbackModal: UnpublishFeedbackModal } = useFeedbackModal({errorMsg: unpublishErrorMsg});

  const onClickUnpublishJob = () => {
    launchUnpublish(unpublishJob);
  };

  // Re-publish a (previously) published job
  const {
    mutate: publishMutation,
    loading: publishLoading,
    errors: publishErrors,
  } = useMutationJobPublish();

  const publishErrorMsg = useServerErrorFormatter(publishErrors);

  const publishJob = () => {
    return publishMutation({
      variables: {
        id: job._id,
      },
    });
  };

  const { launch: launchPublish, FeedbackModal: PublishFeedbackModal } = useFeedbackModal({errorMsg: publishErrorMsg});

  const onClickPublishJob = () => {
    launchPublish(publishJob);
  };

  const deleteJob = () => {
    return deleteMutation({
      variables: {
        id: job._id,
      },
    });
  };

  const onDeleteCompleted = () => {
    redirectTo(ROUTES.EMPLOYER_ACTIVE_JOBS);
  };

  const deleteSuccessMsg = (
    <p>{t("job-dashboard:job_dashboard_delete_success_msg")}</p>
  );
  const deleteErrorMsg = useServerErrorFormatter(deleteErrors);

  const deleteConfirmMsg = (
    <p>{t("job-dashboard:job_dashboard_delete_confirm_msg")}</p>
  );

  const { launch: launchDelete, FeedbackModal: DeleteFeedbackModal } = useFeedbackModal({
      successMsg: deleteSuccessMsg,
      errorMsg: deleteErrorMsg,
      confirmMsg: deleteConfirmMsg
    }
  );

  const onClickDeleteJob = () => {
    launchDelete(deleteJob, onDeleteCompleted);
  };

  return (
    <>
      {DeleteFeedbackModal}
      {PublishFeedbackModal}
      {UnpublishFeedbackModal}
      <div className="JobDashboardSummaryBlock">
        <Row>
          <Col className="job-information-col">
            <Row>
              <Col>
                {(job.city || job.state || job.country) && (
                  <Row>
                    <Col>{addressLine}</Col>
                  </Row>
                )}
                {salaryLine && (
                  <Row>
                    <Col>{salaryLine}</Col>
                  </Row>
                )}
                <Row>
                  <Col>{getEmploymentTypeLabel(t, job.employmentType)}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="job-detail">
                    {isDraft && (
                      <Row>
                        <Col>
                          <Badge className="job-status">
                            {t(
                              "job-dashboard:job_dashboard_draft_mention_text"
                            )}
                          </Badge>
                        </Col>
                      </Row>
                    )}
                    {isPublished && (
                      <Row>
                        <Col>
                          <Badge className="job-status">
                            {t(
                              "job-dashboard:job_dashboard_published_mention_text"
                            )}
                          </Badge>
                        </Col>
                      </Row>
                    )}
                    {isUnpublished && (
                      <Row>
                        <Col>
                          <Badge className="job-status">
                            {t(
                              "job-dashboard:job_dashboard_unpublished_mention_text"
                            )}
                          </Badge>
                        </Col>
                      </Row>
                    )}
                    {isPending && (
                      <Row className="warning-row">
                        <Col className={"button-col"}>
                          <Badge className="job-status">
                            {t("job-dashboard:job_dashboard_pending_job_label")}
                          </Badge>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {!isArchived && (
            <>
              <Col className={"link-col"}>
                <RefreshButton />
              </Col>
              <Col className={"button-col"}>
                <ActionButton onClick={onClickUpdate}>
                  {t("job-dashboard:job_dashboard_update_job_button_label")}
                </ActionButton>
              </Col>
              {!hasBeenPublished && (
                <Col className={"button-col"}>
                  <ActionButton loading={deleteLoading} onClick={() => onClickDeleteJob()}>
                    {t("job-dashboard:job_dashboard_delete_job_button_label")}
                  </ActionButton>
                </Col>
              )}
              {isPublished && (
                <Col className={"button-col"}>
                  <ActionButton loading={unpublishLoading} onClick={() => onClickUnpublishJob()}>
                    {t("job-dashboard:job_dashboard_unpublish_job_button_label")}
                  </ActionButton>
                </Col>
              )}
              {isUnpublished && (
                <Col className={"button-col"}>
                  <ActionButton loading={publishLoading} onClick={() => onClickPublishJob()}>
                    {t("job-dashboard:job_dashboard_republish_job_button_label")}
                  </ActionButton>
                </Col>
              )}
            </>
          )}
        </Row>
        {isArchived && (
          <Row>
            <Col>
              <HiredCandidatesBlock jobId={job._id} loading={deleteLoading} />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default JobDashboardSummaryBlock;
