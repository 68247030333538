import React, {useCallback} from "react";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobUpdate from "./useMutationJobUpdate";
import { STEP_ENUM } from "./JobSaveForm";
import JobSaveSkillsForm from "./JobSaveSkillsForm.js";
import TypeUtils from "../../../utils/TypeUtils.js";

export default function useJobSaveSkillsForm(job, isStepSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { mutate, loading: mutationLoading, errors } = useMutationJobUpdate();

  // If step has already been saved (user previously modified the suggested skills), display user-defined skills,
  // otherwise display suggestions from analysis.
  const initialTechSkills = React.useMemo(
    () =>
      isStepSaved
        ? TypeUtils.ensureArray(job.technicalSkills)
        : TypeUtils.ensureArray(job.nlpRequiredTechnicalSkills),
    [isStepSaved, job.technicalSkills, job.nlpRequiredTechnicalSkills]
  );

  const initialSoftSkills = React.useMemo(
    () =>
      isStepSaved
        ? TypeUtils.ensureArray(job.softSkills)
        : TypeUtils.ensureArray(job.nlpRequiredSoftSkills),
    [isStepSaved, job.softSkills, job.nlpRequiredSoftSkills]
  );

  const [techSkills, setTechSkills] = React.useState(initialTechSkills);
  const [softSkills, setSoftSkills] = React.useState(initialSoftSkills);

  const onChangeTechSkills = React.useCallback(tags => {
    setTechSkills(tags);
  }, []);

  const onChangeSoftSkills = React.useCallback(tags => {
    setSoftSkills(tags);
  }, []);

  // When job is reanalyzed or skills are modified elsewhere, check whether we should suggest the skills from analysis
  React.useEffect(() => {
    setTechSkills(initialTechSkills);
    setSoftSkills(initialSoftSkills);
  }, [initialTechSkills, initialSoftSkills]);

  const canSubmit = useCallback(() => {
    return true;
  }, []);

  const submit = useCallback(() => {
    return mutate({
      variables: {
        id: job._id,
        technicalSkills: techSkills,
        softSkills: softSkills,
        lastSavedStep: isStepSaved ? undefined : STEP_ENUM.SKILLS,
      },
    });
  }, [mutate, job._id, techSkills, softSkills, isStepSaved]);

  const formProps = React.useMemo(() => ({
    t,
    loading: tLoading,
    isStepSaved,
    softSkills,
    techSkills,
    onChangeSoftSkills,
    onChangeTechSkills
  }), [t, tLoading, isStepSaved, softSkills, techSkills, onChangeSoftSkills, onChangeTechSkills]);

  return {
    form: JobSaveSkillsForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
