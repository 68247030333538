import React from "react";
import "./ErrorModal.scss";
import ErrorModal from "./ErrorModal";

/**
 * Build a modal to display an error message.
 * The purpose of this hook is to hide the management of the modal show state.
 * @param Component Error message to display in the modal, as a React component
 * @param onDismiss Callback to call when the user dismisses the modal (optional)
 * @returns {{ContentModal: *, show: (*|(function(): void))}} A modal component and a callback to show it
 */
export default function useErrorModal(Component, onDismiss = undefined) {

  // TODO: call useGenericModal instead of repeating code.

  // Show the modal.
  // Optimization: Define at construction time only
  const show = React.useCallback(() => {
    setVisible(true);
  }, []);

  // Hide the modal
  // Optimization: Define at construction time only
  const hide = React.useCallback(() => {
    setVisible(false);
    if (onDismiss)
      onDismiss();
  }, [onDismiss]);

  const [visible, setVisible] = React.useState(false);

  const modal = <ErrorModal show={visible} onDismiss={hide}>{Component}</ErrorModal>;

  return {ErrorModal: modal, show};
}
