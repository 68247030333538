import React from "react";
import { useController } from "react-hook-form";
import useTranslationCommon from "../useTranslationCommon";
import "./ValidatedInput.scss";
import "./ValidatedInputSelect.scss";
import StringUtils from "../../../utils/StringUtils";
import TypeUtils from "../../../utils/TypeUtils";
import {FormControl} from "react-bootstrap";

export default function ValidatedInputSelect(props) {
  const { t, loading: tLoading } = useTranslationCommon();
  const { name, control, inputRef, defaultValue, choices, required, withBlankOption, placeholder, ...otherProps } = props;

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    rules: { required },
    name,
    control,
    defaultValue: StringUtils.nullToEmpty(defaultValue),
  });

  let errorMessage = "";

  if (error && !tLoading) {
    switch (error.type) {

      case "required":
        errorMessage = t("common:common_input_required");
        break;

      default:
        errorMessage = error.type;
    }
  }

  return (
    <div className={`ValidatedInput ValidatedInputSelect ${StringUtils.isNullOrEmpty(value) ? "blank-option-selected" : ""}`} >
      <FormControl
        name={name}
        as={"select"}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...otherProps}
      >
        {withBlankOption &&
          <option
            key={"blankChoice"}
            label={StringUtils.nullToEmpty(placeholder)}
            value={""}
            className={"blank-option"}
          />
        }
        {TypeUtils.ensureArray(choices).map(choice =>
          StringUtils.isNullOrEmpty(choice.value) ?
          null :
          <option
            key={choice.value}
            label={StringUtils.isNullOrEmpty(choice.label) ? choice.value : choice.label}
            value={choice.value}
          />
        )}
      </FormControl>
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
}
