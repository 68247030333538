import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../utils/StringUtils";
import "./JobSaveCommissionForm.scss";
import ValidatedInputText from "../../common/widgets/ValidatedInputText";
import WaitForData from "../../common/data-fetching/WaitForData";
import JobSaveCommissionTip from "./JobSaveCommissionTip";
import HtmlUtils from "../../../utils/HtmlUtils";
import useQueryJobGlobalMaxCommission from "../view/useQueryJobGlobalMaxCommission.js";
import useQueryJobGlobalAverageCommission from "../view/useQueryJobGlobalAverageCommission.js";
import JobStatusEnum from "../JobStatusEnum.js";
import useQueryJobPublicSearch from "../search/useQueryJobPublicSearch.js";
import TypeUtils from "../../../utils/TypeUtils.js";

const minCommission = !StringUtils.isNullOrEmpty(
  process.env.REACT_APP_COMMISSION_MIN
)
  ? process.env.REACT_APP_COMMISSION_MIN
  : 100;
const maxCommission = !StringUtils.isNullOrEmpty(
  process.env.REACT_APP_COMMISSION_MAX
)
  ? process.env.REACT_APP_COMMISSION_MAX
  : 1000;

export default function JobSaveCommissionForm({t, loading, control, job}) {
  useShowLoader(loading);

  // Get max commission
  const {
    data: jobGlobalMaxCommission,
    loading: jobGlobalMaxCommissionLoading,
    errors: jobGlobalMaxCommissionErrors,
  } = useQueryJobGlobalMaxCommission();

  // Get average commission
  const {
    data: jobGlobalAverageCommission,
    loading: jobGlobalAverageCommissionLoading,
    errors: jobGlobalAverageCommissionErrors,
  } = useQueryJobGlobalAverageCommission();

  // Get similar jobs
  // Memoize searchFilters to avoid re-rendering of components that use them on every render of this component
  const searchFilters = React.useMemo(
    () => ({
      queryText: TypeUtils.arrayRemoveNullOrEmpty([job.title, ...TypeUtils.ensureArray(job.technicalSkills)]).slice(0, 10),
      status: JobStatusEnum.PUBLISHED,
    }),
    [job.title, job.technicalSkills]
  );

  const {
    loading: similarJobsLoading,
    errors: similarJobsErrors,
    data: similarJobs,
  } = useQueryJobPublicSearch(10, searchFilters, "DATE_POSTED_DESC");

  return (
    <div className="JobSaveCommissionForm ">
      <Row>
        <Col className={"form-col spaced-children widget-block"}>
          <Row className="reward">
            <Col>
              {t("jobs:employers_job_save_commission_step_invite_reward")}
            </Col>
          </Row>
          <Row className="note1">
            <Col>
              {t("jobs:employers_job_save_commission_step_invite_note1")}
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  {t("jobs:employers_job_save_commission_min", {
                    min: minCommission,
                  })}
                </Col>
              </Row>
              <Row>
                <Col>
                  {t("jobs:employers_job_save_commission_max", {
                    max: maxCommission,
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="over-commission-max">
            <Col>
              {t("jobs:employers_job_save_over_commission_max", {
                max: maxCommission,
              })}
            </Col>
          </Row>
          <Row>
            <Col className="commission-col">
              <span className={"currency"}>{t("jobs:employers_job_save_currency")}</span>&nbsp;
              <ValidatedInputText
                name="commission"
                control={control}
                type="number"
                step="1"
                min={minCommission}
                defaultValue={job.commission}
                max={maxCommission}
                required
                placeholder="$"
              />
            </Col>
          </Row>
        </Col>
        <Col className={"tip-col widget-block"}>
          <WaitForData
            loading={
              similarJobsLoading ||
              jobGlobalMaxCommissionLoading ||
              jobGlobalAverageCommissionLoading
            }
            errors={
              similarJobsErrors ||
              jobGlobalMaxCommissionErrors ||
              jobGlobalAverageCommissionErrors
            }
            onLoaded={() => (
              <JobSaveCommissionTip
                jobGlobalAverageCommission={jobGlobalAverageCommission}
                jobGlobalMaxCommission={jobGlobalMaxCommission}
                similarJobs={similarJobs}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col className={"widget-block"}>
          <p className="payment-terms-title">{t("jobs:employers_job_save_reward_terms_msg_1")}</p>
          {HtmlUtils.htmlToComponents(job.organization.paymentTerms)}
          <p className={"sidenote"}>{t("jobs:employers_job_save_reward_terms_msg_2")}</p>
          <p className={"payment-terms-title"}>{t("jobs:employers_job_save_reward_terms_msg_3")}</p>
          <p>{t("jobs:employers_job_save_reward_terms_msg_4")}</p>
          <p>{t("jobs:employers_job_save_reward_terms_msg_5")}</p>
        </Col>
      </Row>
    </div>
  );
}
