import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobs from "../useTranslationJobs";
import JobSearchItem from "./JobSearchItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionButton from "../../common/widgets/ActionButton";

export default function SimilarJobsProfile(props) {
  const { jobs, onClose } = props;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "SimilarJobsProfile");

  const searchResult =
    <JobSearchItem
      searchFilters={jobs}
    />;

  return (
    <div className="SimilarJobsProfile">
      {jobs.map((item, index) =>
        React.cloneElement(searchResult, {
          key: index,
          item,
        }))
      }
      <Row>
        <Col className="modal-actions">
          <ActionButton onClick={onClose}>
            {t("jobs:employers_job_similar_jobs_close_action")}
          </ActionButton>
        </Col>
      </Row>
    </div>
  );
}
