import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";
import { JOB_TYPE_ALL_FIELDS_FRAGMENT } from "../view/useQueryJobById";
import useLanguages from "../../infra-no-ui/translation/useLanguages";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobUnpublish";

export const QUERY = gql`
    ${JOB_TYPE_ALL_FIELDS_FRAGMENT}
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
        $id: ID!
        $lang: GlobalLanguageEnum!
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id
        ) {
          ...JobTypeAllFieldsFragment
        }
      }
    }
  `;

export default function useMutationJobUnpublish() {
  const {getCurrentLanguageCode} = useLanguages();
  const variables = {lang: getCurrentLanguageCode().toUpperCase()};

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {variables});
}
