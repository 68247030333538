import React from "react";
import useLazyQueryResumeDownloadUrl from "./useLazyQueryResumeDownloadUrl";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../utils/StringUtils";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import useErrorModal from "../../common/modals/useErrorModal";
import useTranslationCandidates from "../useTranslationCandidates";
import ActionDiv from "../../common/widgets/ActionDiv";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ActionLink from "../../common/widgets/ActionLink";

export default function ResumeDownloadIcon(props) {
  const { resumeId, showLabel } = props;
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ResumeDownloadIcon");

  const onClickDownload = (event) => {
    event.preventDefault();

    return fetch({ id: resumeId })
      .then((downloadUrl) => {
        if (!StringUtils.isNullOrEmpty(downloadUrl)) {
          // This will trigger the file download (window.open(downloadUrl) produces a glitch)
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.click();
        }
      })
      .catch((error) => {
        setError(error);
        showErrorModal();
      });
  };

  const { fetch /*loading*/ } = useLazyQueryResumeDownloadUrl();
  // error returned by useLazyQueryResumeDownloadUrl is not updated after fetch, we have to manage it ourselves
  const [error, setError] = React.useState(null);
  const errorMsg = useServerErrorFormatter(error);
  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("candidates:candidates_resume_download_error")}</p>
      {errorMsg}
    </>
  );

  return (
    <>
      {ErrorModal}
      <div className={"ResumeDownloadIcon"}>
        <ActionDiv onClick={onClickDownload}>
          <CloudDownloadIcon
            alt={t("candidates:candidates_details_download_resume_action")}
          />
        </ActionDiv>
        {showLabel && (
          <ActionLink onClick={onClickDownload}>
            {t("candidates:candidates_details_download_resume_action")}
          </ActionLink>
        )}
      </div>
    </>
  );
}
