import React, {useMemo} from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import useTranslationCandidates from "../useTranslationCandidates";
import CandidateApplicationPreview from "./CandidateApplicationPreview";
import "./ApplicationsList.scss";
import TypeUtils from "../../../utils/TypeUtils";
import ThreeStateCheckbox from "../../common/components/ThreeStateCheckbox";
import ApplicationStatusEnum from "../ApplicationStatusEnum";
import useApplicationUpdateStatus from "../mutate/useApplicationUpdateStatus.js";

export default function ApplicationsList({ applications, status, onChangeStatus, job }) {

  const {ready: applicationUpdateStatusReady, BatchUpdateStatusModal, launchChangeStatus} = useApplicationUpdateStatus(job);

  // Initialize a map of application selected status by application IDs. Status is initialized to false.
  const updateApplicationsSelectMap = (
    applicationList,
    applicationsSelectMap
  ) => {
    const updatedApplicationsSelectMap = {};

    // Add entries from applicationList. This will ignore entries from applicationsSelectMap that are no longer useful.
    // Re-use select status from applicationsSelectMap if application is not a new one, otherwise default select status
    // for new applications is false.
    TypeUtils.ensureArray(applicationList).forEach((application) => {
      if (applicationsSelectMap[application._id]) {
        updatedApplicationsSelectMap[application._id] =
          applicationsSelectMap[application._id];
      } else {
        updatedApplicationsSelectMap[application._id] = false;
      }
    });

    return updatedApplicationsSelectMap;
  };

  const [selection, setSelection] = React.useState({
    selectAll: false,
    applicationsSelect: updateApplicationsSelectMap(applications, {}),
  });

  const selectedIds = useMemo(() =>
    Object.keys(selection.applicationsSelect).filter(element => selection.applicationsSelect[element] === true),
    [selection.applicationsSelect]
  )

  // Checkbox status is indeterminate when some applications are selected and some other are not
  const allChecked = Object.values(selection.applicationsSelect).every(isChecked);
  const allUnchecked = Object.values(selection.applicationsSelect).every(isUnchecked);
  const indeterminate = !(allChecked || allUnchecked);

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading || !applicationUpdateStatusReady, "ApplicationsList");

  const STATUS_ENUM = {
    STACK: {
      id: ApplicationStatusEnum.STACK,
      label: t("candidates:candidates_send_to_stack_label"),
    },
    REJECTED: {
      id: ApplicationStatusEnum.REJECTED,
      label: t("candidates:candidates_send_to_rejected_label"),
    },
    SHORTLIST: {
      id: ApplicationStatusEnum.SHORTLIST,
      label: t("candidates:candidates_send_to_shortlist_label"),
    },
    HIRED: {
      id: ApplicationStatusEnum.HIRED,
      label: t("candidates:candidates_send_to_hired_label"),
    },
  };

  // Update selection when the list of applications passed to the component changes.
  // Beware, though, that this effect runs AFTER the render, which means that when an application is added,
  // there is a render where there won't be a value for this application in applicationsSelect.
  React.useEffect(() => {
    setSelection((prevSelection) => {
      const applicationsSelect = updateApplicationsSelectMap(
        applications,
        prevSelection.applicationsSelect
      );
      return {
        selectAll: calculateSelectAllStatus(applicationsSelect, prevSelection.selectAll),
        applicationsSelect: applicationsSelect,
      };
    });
  }, [applications]);

  const handleSelectAll = () => {
    setSelection(prevSelection => {
      const newApplicationSelection = {};
      Object.keys(prevSelection.applicationsSelect).forEach((element) => {
        newApplicationSelection[element] = !prevSelection.selectAll;
      });
      return ({
        selectAll: !prevSelection.selectAll,
        applicationsSelect: newApplicationSelection,
      });
    });
  };

  const handleApplicationSelect = (event) => {
    setSelection((prevSelection) => {

      // Update the status of the select application
      const applicationsSelect = {
        ...prevSelection.applicationsSelect,
        [event.target.name]: event.target.checked
      };

      return {
        selectAll: calculateSelectAllStatus(applicationsSelect, prevSelection.selectAll),
        applicationsSelect: applicationsSelect,
      };
    });
  };

  const handleChangeStatus = (status) => {
    launchChangeStatus(selectedIds, status, () => onChangeStatus(status))
  }

  const dropdownItems = Object.keys(STATUS_ENUM).filter(
    (item) => STATUS_ENUM[item].id !== status
  );

  return (
    <>
      {BatchUpdateStatusModal}
      <div className={"ApplicationsList"}>
        <Row>
          <Col>
            <ThreeStateCheckbox
              checked={selection.selectAll}
              indeterminate={indeterminate}
              onChange={handleSelectAll}
              name="selectAll"
              label={t("candidates:candidates_select_all_label")}
            />
          </Col>
          {!allUnchecked &&
          <Col className="action-col">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {t("candidates:candidates_send_to_label")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {dropdownItems.map((item) => (
                  <Dropdown.Item
                    disabled={allUnchecked}
                    key={item}
                    onClick={() => handleChangeStatus(STATUS_ENUM[item].id)}
                  >
                    {STATUS_ENUM[item].label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          }
        </Row>

        {applications.map((application, i) => (
          <Row key={i}>
            <Col className="widget-block">
              <CandidateApplicationPreview
                application={application}
                selected={
                  selection.applicationsSelect[application._id] || false
                }
                handleApplicationSelect={handleApplicationSelect}
                onChangeStatus={onChangeStatus}
              />
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
}

const isChecked = (currentValue) => currentValue === true;
const isUnchecked = (currentValue) => currentValue === false;

const calculateSelectAllStatus = (applicationsSelect, prevSelectAll) => {
  // Calculate the status of the selectAll checkbox wrt application checkboxes
  const applicationsSelectValues = Object.values(applicationsSelect);
  const allChecked = applicationsSelectValues.every(isChecked);
  const allUnchecked = applicationsSelectValues.every(isUnchecked);
  let selectAll = prevSelectAll;
  if (allChecked)
    selectAll = true;
  else if (allUnchecked)
    selectAll = false;
  return selectAll;
}
