import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";
import useLanguages from "../../infra-no-ui/translation/useLanguages.js";

const QUERY_SCOPE = "application";
const QUERY_NAME = "applicationById";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: ID!, $lang: GlobalLanguageEnum!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(id: $id) {
        _id
        candidateId
        candidate {
          _id
          name
          phone
          email
          linkedin
          indeed
        }
        recruiterId
        recruiter {
          _id
          firstName
          lastName
          phone
          email
          aboutMe
          isActiveAccount
        }
        job {
          commission
          organization {
            paymentTerms(lang: $lang)
          }
        }
        hire {
          hiringDate
          dateCancelled
        }
        recruiterNote
        employerNote
        resumeId
        status
        createdAt
      }
    }
  }
`;

export default function useQueryApplicationById(id) {
  const {getCurrentLanguageCode} = useLanguages();

  const variables = {
    id,
    lang: getCurrentLanguageCode().toUpperCase()
  };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
