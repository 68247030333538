import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ValidatedInputText from "../../common/widgets/ValidatedInputText";
import "./JobSaveTitleForm.scss";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";

export default function JobSaveTitleForm({t, control, job, loading}) {
  useShowLoader(loading);

  return (
    <div className="JobSaveTitleForm ">
      <Row>
        <Col>
          <div className="job-title-container">
            <Row className="job-title">
              <Col>{t("jobs:employers_job_save_title_invite_msg")}</Col>
            </Row>
            <Row>
              <Col>
                <ValidatedInputText
                  type="text"
                  required={true}
                  name="title"
                  control={control}
                  defaultValue={job.title}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

