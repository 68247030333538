import React from "react";
import Page from "../../common/page/Page";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import useTranslationChatGpt from "../useTranslationChatGpt.js";
import ChatGptBlock from "./ChatGptBlock.js";

export default function ChatGptPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationChatGpt();
  useShowLoader(tLoading, "ChatGptPage");

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="ChatGptPage">
          <Page.Title
            text={t("chatgpt:page_title")}
            loading={tLoading}
          />
          <Page.Content>
            <ChatGptBlock/>
          </Page.Content>
        </Page>
      )}
    />
  );
}
