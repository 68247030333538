import React, {useCallback, useMemo} from "react";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobUpdate from "./useMutationJobUpdate";
import { STEP_ENUM } from "./JobSaveForm";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";
import JobWorkModeEnum from "../JobWorkModeEnum";
import StringUtils from "../../../utils/StringUtils";
import JobSaveLocationForm from "./JobSaveLocationForm.js";

export default function useJobSaveLocationForm(job, isStepSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { mutate, loading: mutationLoading, errors } = useMutationJobUpdate();

  const {
    control,
    formState: { isValid },
    getValues,
    reset,
    watch,
  } = useValidatedInputForm();

  const canSubmit = useCallback(() => {
    return isValid;
  }, [isValid]);

  const submit = useCallback(() => {
    const fields = getValues();
    return mutate({
      variables: {
        id: job._id,
        workMode: StringUtils.isNullOrEmpty(fields.workMode) ? null : fields.workMode,
        city: fields.city,
        state: fields.state,
        country: fields.country,
        isWorkPermitRequired: fields.isWorkPermitRequired,
        lastSavedStep: isStepSaved ? undefined : STEP_ENUM.LOCATION,
      },
    });
  }, [getValues, mutate, job._id, isStepSaved]);

  // City, state and province are required unless job is fully remote
  const isLocationRequired = watch("workMode") !== JobWorkModeEnum.REMOTE;
  // Country is required when a valid work permit is required for foreigners
  const isCountryRequired = !!watch("isWorkPermitRequired");

  // Force the form to revalidate itself when the remote status or work permit requirement change (because the required status of some fields changes as well)
  React.useEffect(() => {
    reset(getValues())
  }, [isLocationRequired, isCountryRequired, reset, getValues]);

  const formProps = useMemo(() => ({
    t,
    loading: tLoading,
    control,
    job,
    isLocationRequired,
    isCountryRequired
  }), [t, tLoading, control, job, isLocationRequired, isCountryRequired]);

  return {
    form: JobSaveLocationForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
