import React from "react";
import Page from "../../common/page/Page";
import useTranslationAdmin from "../useTranslationAdmin";
import AdminBlock from "./AdminBlock";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";


export default function AdminPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "AdminPage");

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="AdminPage">
          <Page.Title text={t("admin:admin_dashboard_title")} loading={tLoading} />
          <Page.Content>
            <AdminBlock />
          </Page.Content>
        </Page>
      )}
    />
  );
}
