import {useCallback, useMemo} from "react";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobCreate from "./useMutationJobCreate";
import useMutationJobUpdateDescription from "./useMutationJobUpdateDescription";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";
import EmploymentTypeEnum from "../EmploymentTypeEnum";
import JobWorkModeEnum from "../JobWorkModeEnum";
import {STEP_ENUM} from "./JobSaveForm.js";
import JobSaveTitleForm from "./JobSaveTitleForm.js";

export default function useJobSaveTitleForm(job, isStepSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const {
    mutate: creationMutate,
    loading: creationLoading,
    errors: creationErrors,
  } = useMutationJobCreate();
  const {
    mutate: updateMutate,
    loading: updateLoading,
    errors: updateErrors,
  } = useMutationJobUpdateDescription();

  const errorMsgs = creationErrors || updateErrors;

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = useCallback(() => {
    return isValid;
  }, [isValid]);

  const submit = useCallback(() => {
    const values = getValues();

    if (job._id) {
      return updateMutate({
        variables: {
          id: job._id,
          title: values.title,
          lastSavedStep: isStepSaved ? undefined : STEP_ENUM.TITLE
        },
      });
    } else {
      return creationMutate({
        variables: {
          employerId: job.employerId,
          title: values.title,
          lastSavedStep: STEP_ENUM.TITLE,
          // Init some default values
          employmentType: EmploymentTypeEnum.FULL_TIME,
          workMode: JobWorkModeEnum.IN_PERSON,
          isWorkPermitRequired: true,
          state: "Québec",
          country: "Canada"
        },
      });
    }
  }, [updateMutate, creationMutate, getValues, job._id, isStepSaved, job.employerId]);

  const formProps = useMemo(() => ({
    t,
    control,
    job
  }), [t, control, job]);

  return {
    form: JobSaveTitleForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: creationLoading || updateLoading,
    errors: errorMsgs,
  };
}
