import React from "react";
import * as ROUTES from "../../constants/routes";
import Nav from "react-bootstrap/Nav";
import "./Menu.scss";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import isUserAuthorized from "../authentication-no-ui/isUserAuthorized";
import { MenuDropdown, MenuDropdownItem, MenuItem } from "./MenuComponents";
import useTranslationMenu from "./useTranslationMenu";

export default function Menu() {
  const { t, loading: tLoading } = useTranslationMenu();
  const authUser = useAuthUser();
  const isGptEnabled = authUser && authUser.profile && authUser.profile.organization && authUser.profile.organization.isGptEnabled;

  // Don't use useShowLoader because there is no loader to display over this component; just wait until there is something to display.
  if (tLoading) {
    return null;
  }

  // User might be authenticated but might not have a profile, if we are displaying the missing profile page
  const userIsAuthenticated = authUser && authUser.authenticated;

  // My account menu items
  const accountMenuItems = [];

  if (isUserAuthorized(authUser)) {
    accountMenuItems.push(
      <MenuDropdownItem key="menu_item_profile" to={ROUTES.EMPLOYER_PROFILE}>
        {t("menu:menu_item_profile")}
      </MenuDropdownItem>
    );
    accountMenuItems.push(
      <MenuDropdownItem key="menu_item_sign_out" to={ROUTES.SIGN_OUT}>
        {t("menu:menu_item_sign_out")}
      </MenuDropdownItem>
    );
  }

  return (
    <Nav className="Menu basepage-fade-in">
      {/*userIsAuthenticated && (
        <MenuItem key="menu_item_dashboard" to={ROUTES.EMPLOYER_DASHBOARD}>
          {t("menu:menu_item_dashboard")}
        </MenuItem>
      )*/}
      {isUserAuthorized(authUser, "CHATGPT_VIEW", isGptEnabled) && (
        <MenuItem key="menu_item_chatgpt" to={ROUTES.EMPLOYER_CHATGPT}>
          {t("menu:menu_item_chatgpt")}
        </MenuItem>
      )}
      {userIsAuthenticated && (
        <MenuItem key="menu_item_jobs" to={ROUTES.EMPLOYER_ACTIVE_JOBS}>
          {t("menu:menu_item_jobs")}
        </MenuItem>
      )}
      {userIsAuthenticated && (
        <MenuDropdown
          title={t("menu:menu_item_account")}
          items={accountMenuItems}
        />
      )}

      {!userIsAuthenticated && (
        <MenuItem to={ROUTES.PUBLIC_URL} external>
          {t("menu:menu_item_for_recruiters")}
        </MenuItem>
      )}
      {!userIsAuthenticated && (
        <MenuItem to={ROUTES.SIGN_UP}>
          {t("menu:menu_item_sign_up")}
        </MenuItem>
      )}
      {!userIsAuthenticated && (
        <MenuItem className="menu-item-outlined" to={ROUTES.CONNECTION_MODE}>
          {t("menu:menu_item_sign_in")}
        </MenuItem>
      )}
    </Nav>
  );
}
