import {gql} from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";
import { QUERY as QUERY_JOBS_SEARCH } from "../../dashboard/search/useQueryJobsSearch";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobDelete";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} ($id: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME} (id: $id) {
        _id
      }
    }
  }
`;

export default function useMutationJobDelete() {

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {
    refetchQueries: [QUERY_JOBS_SEARCH],
  });
}
