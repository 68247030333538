import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Table from "react-bootstrap/Table";
import useQueryJobAdminSearch from "../search/useQueryJobAdminSearch";
import WaitForData from "../../common/data-fetching/WaitForData";
import Switch from "react-switch";
import useMutationJobMarkPublished from "../mutate/useMutationJobMarkPublished.js";
import useErrorModal from "../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import ActionLink from "../../common/widgets/ActionLink";
import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";
import "./JobList.scss";
import JobStatusEnum from "../../jobs/JobStatusEnum";
import JobPaymentStatusEnum from "../../jobs/JobPaymentStatusEnum";
import useMutationJobMarkPaid from "../mutate/useMutationJobMarkPaid.js";

export default function JobList(props) {
  const { status, paymentStatus } = props;
  const {
    data: jobs,
    loading,
    errors,
  } = useQueryJobAdminSearch(
    50,
    {
      status,
      paymentStatus,
    },
    "CREATED_AT_DESC"
  );

  const {
    mutate: mutatePublished,
    loading: publishedLoading,
    errors: publishedErrors,
  } = useMutationJobMarkPublished();

  const {
    mutate: mutatePaid,
    loading: paidLoading,
    errors: paidErrors,
  } = useMutationJobMarkPaid();

  const errorMsg = useServerErrorFormatter(publishedErrors || paidErrors);

  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading || loading || publishedLoading || paidLoading, "JobList");

  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("admin:admin_job_update_error")}</p>
      {errorMsg}
    </>
  );

  const columnsLabels = [
    t("admin:admin_job_title_column_label"),
    t("admin:admin_job_company_column_label"),
    t("admin:admin_job_publishing_status_label"),
    t("admin:admin_job_payment_status_column_label"),
  ];

  const isPaid = (status) => status === JobPaymentStatusEnum.PAID;
  const isPublished = (status) => status === JobStatusEnum.PUBLISHED;

  const handlePaymentChange = (id, status) => {
    const variables = {};
    variables["id"] = id;
    variables["paid"] = !isPaid(status)
    mutatePaid({ variables }).catch((/*error*/) => {
      showErrorModal();
    });
  };

  const handlePublishingChange = (id, status) => {
    const variables = {};
    variables["id"] = id;
    variables["published"] = !isPublished(status)
    mutatePublished({ variables }).catch((/*error*/) => {
      showErrorModal();
    });
  };

  const onClickViewJob = (id) => {
    const jobTab = window.open(
      RouterUtils.injectParamsInRoute(ROUTES.PUBLIC_JOB_URL, {
        jobId: id,
      }),
      "_blank"
    );
    jobTab.focus();
  };

  return (
    <>
      {ErrorModal}
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <Row className="JobList">
            <Col>
              {!jobs["nodes"].length > 0 && t("admin:admin_no_job_message")}
              {jobs["nodes"].length > 0 && (
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      {columnsLabels.map((item, i) => (
                        <td key={i}>{item}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {jobs["nodes"].map((item, i) => (
                      <tr key={i}>
                        <th>{i + 1}</th>
                        <th>
                          <ActionLink
                            onClick={() => onClickViewJob(item._id)}
                            className="action-link"
                          >
                            {item.title}
                          </ActionLink>
                        </th>
                        <th>{item.companyName}</th>
                        <th>
                          <Switch
                            onChange={() => {
                              handlePublishingChange(item._id, item.status);
                            }}
                            checked={isPublished(item.status)}
                          />
                        </th>
                        <th>
                          <Switch
                            onChange={() => {
                              handlePaymentChange(item._id, item.paymentStatus);
                            }}
                            checked={isPaid(item.paymentStatus)}
                          />
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        )}
      />
    </>
  );
}
