import useModuleTranslation from "../infra-no-ui/translation/useModuleTranslation";
import React from "react";

/**
 * Dynamically load translation files pertaining to the admin module.
 * @return {*}
 */
export default function useTranslationAdmin() {

  // Imports must be memoized so that they don't change on every render
  const imports = React.useMemo(() => ({
    'en': import('./locales/en/admin.json'),
    'fr': import('./locales/fr/admin.json')
  }), []);

  return useModuleTranslation('admin', imports);
}
