import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import HtmlUtils from "../../../utils/HtmlUtils";
import "./JobSavePublicationForm.scss";

export default function JobSavePublicationForm({t, loading, job}) {
  useShowLoader(loading);

  const commission = job.commission;

  return (
    <div className="JobSavePublicationForm">
      <Row className="title-row">
        <Col>{t(job.organization.isSubscriptionActive ? "jobs:employers_job_save_publish_step_label" : "jobs:employers_job_save_publish_step_invite")}</Col>
      </Row>
      <Row>
        <Col className="message-col-block">
          <p className={"title"}>{HtmlUtils.htmlToComponents(
            t("jobs:employers_job_save_publish_question_1")
          )}</p>
          <p>
            {HtmlUtils.htmlToComponents(
              t("jobs:employers_job_save_publish_message_1")
            )}
          </p>
          <p>
            {HtmlUtils.htmlToComponents(
              t("jobs:employers_job_save_publish_message_1b")
            )}
          </p>
          {!job.organization.isSubscriptionActive &&
          <p>
            {HtmlUtils.htmlToComponents(
              t("jobs:employers_job_save_publish_message_1c")
            )}
          </p>
          }
          <p className={"title"}>
            {HtmlUtils.htmlToComponents(
              t("jobs:employers_job_save_publish_question_2")
            )}
          </p>
          <p>
            {HtmlUtils.htmlToComponents(
              t("jobs:employers_job_save_publish_message_2", {
                commission,
              })
            )}
          </p>
          <p className={"title"}>
            {HtmlUtils.htmlToComponents(
              t("jobs:employers_job_save_publish_question_3")
            )}
          </p>
          <p>
            {HtmlUtils.htmlToComponents(
              t("jobs:employers_job_save_publish_message_3")
            )}
          </p>
        </Col>
      </Row>
    </div>
  );
}
