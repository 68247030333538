import React, {useCallback, useMemo} from "react";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobUpdate from "./useMutationJobUpdate";
import { STEP_ENUM } from "./JobSaveForm";
import StringUtils from "../../../utils/StringUtils";
import JobSaveSalaryForm from "./JobSaveSalaryForm.js";

export default function useJobSaveSalaryForm(job, isStepSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { mutate, loading: mutationLoading, errors } = useMutationJobUpdate();

  const [fields, setFields] = React.useState({
    employmentType: StringUtils.nullToEmpty(job.employmentType),
  });

  const onChange = React.useCallback(event => {
    setFields((prevFields) => ({
      // Copy all field values from previous state
      ...prevFields,
      // Override the value that just changed
      [event.target.name]: event.target.value,
    }));
  }, [setFields]);

  const canSubmit = useCallback(() => {
    return true;
  }, []);

  const submit = useCallback(() => {
    return mutate({
      variables: {
        id: job._id,
        employmentType: StringUtils.isNullOrEmpty(fields.employmentType) ? null : fields.employmentType,
        lastSavedStep: isStepSaved ? undefined : STEP_ENUM.SALARY,
      },
    });
  }, [mutate, job._id, fields.employmentType, isStepSaved]);

  const formProps = useMemo(() => ({
    t,
    loading: tLoading,
    employmentType: fields.employmentType,
    onChange
  }), [t, tLoading, fields.employmentType, onChange]);

  return {
    form: JobSaveSalaryForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
