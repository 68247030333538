import React, {useCallback, useEffect, useState} from "react";
import TypeUtils from "../../../utils/TypeUtils.js";
import ChatMessage from "./ChatMessage.js";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {FormControl} from "react-bootstrap";
import ActionButton from "../widgets/ActionButton.js";
import useShowLoader from "../loading-widgets/useShowLoader.js";
import useTranslationCommon from "../useTranslationCommon.js";
import "./ChatWindow.scss";
import MathUtils from "../../../utils/MathUtils.js";

export default function ChatWindow({remoteUserComponent, localUserComponent, messages, onSubmit, isSubmitting}) {

  const { t, loading: tLoading } = useTranslationCommon();
  useShowLoader(tLoading, "ChatGptBlock");

  const [prompt, setPrompt] = useState("");

  const getNewRandomWaitingMessage = useCallback(() => {
    // Use tLoading to add a dependency on tLoading because t doesn't change when translations are ready
    if (!tLoading) {
      const randomWaitingStrings = [
        t("common:chat_waiting_msg_1"),
        t("common:chat_waiting_msg_2"),
        t("common:chat_waiting_msg_3"),
        t("common:chat_waiting_msg_4"),
        t("common:chat_waiting_msg_5")
      ];
      return {
        source: "wait",
        content: randomWaitingStrings[MathUtils.getRandomInt(4)]
      }
    } else {
      return {
        source: "wait",
        content: ""
      }
    }
  }, [t, tLoading]);

  const [currentWaitingMessage, setCurrentWaitingMessage] = useState(null);

  useEffect(() => {
    if (isSubmitting) {
      // Use a timeout to display waiting message to have some action while the user wait
      const timer = setTimeout(() => {
        setCurrentWaitingMessage(getNewRandomWaitingMessage());
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isSubmitting, getNewRandomWaitingMessage]);

  const onClickSend = useCallback((event) => {
    event.preventDefault();
    return onSubmit(prompt)
      .then(() => setPrompt(""))
      .catch(error => {/* Just catch to avoid JS warning */})
  }, [onSubmit, prompt]);

  return (
    <div className="ChatWindow">
      <Row className={"conversation-area"}>
        <Col className={"conversation-col"}>
          {TypeUtils.ensureArray(messages).map((message, index) =>
            <Row key={index} >
              <Col>
                <ChatMessage message={message} remoteUserComponent={remoteUserComponent} localUserComponent={localUserComponent}/>
              </Col>
            </Row>
          )}
          {isSubmitting && currentWaitingMessage !== null &&
            <Row>
              <Col>
                <ChatMessage message={currentWaitingMessage} remoteUserComponent={remoteUserComponent} localUserComponent={localUserComponent}/>
              </Col>
            </Row>
          }
        </Col>
      </Row>
      <Row className={"prompt-area"}>
        <Col>
          <Form autoComplete={"off"}>
            <Row>
              <Col className="prompt-col">
                <FormControl
                  className="input"
                  name="prompt"
                  onChange={(event) => setPrompt(event.target.value)}
                  type="text"
                  value={prompt}
                  placeholder={t("common:chat_prompt_placeholder")}
                  disabled={isSubmitting}
                />
              </Col>
              <Col className="submit-col">
                <ActionButton
                  type="submit"
                  onClick={onClickSend}
                  disabled={prompt.trim().length < 1}
                  loading={isSubmitting}
                >
                  {t("common:chat_send_action")}
                </ActionButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
