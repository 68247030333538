import React from "react";
import { Parser } from "html-to-react";
import StringUtils from "./StringUtils";

export default class HtmlUtils {
  /**
   * Removes the html tags of a string and returns the text content. (for simple HTML tags only)
   * @param html Html string
   * @returns {*} Text from html string
   */
  static removeHtml(html) {
    return StringUtils.nullToEmpty(html).replace(/(<([^>]+)>)/gi, "");
  }

  /**
   * Converts newlines in a string to <br/> tags. Return a series of React components.
   * @param text Text to split
   * @returns {string|unknown[]|number}
   */
  static nlToBr(text) {
    const newline = '\n';

    // If null, undefined, not a number nor a string, return nothing. If a number, return the number. If empty string, return nothing.
    if (typeof text === 'number') {
      return text;
    } else if (typeof text !== 'string') {
      return '';
    } else if (text === '') {
      return '';
    }

    let lines = text.split(newline);
    return lines.map((line, index) =>
      <React.Fragment key={index}>
        {line}<br/>
      </React.Fragment>
    );
  }

  /**
   * Converts newlines in a string to <p> tags and returns the result as a string.
   * @param text Text to split
   * @returns {string|unknown[]|number}
   */
  static nlToParagraphString(text) {
    const newline = '\n';

    // If null, undefined, not a number nor a string, return nothing. If a number, return the number. If empty string, return nothing.
    if (typeof text === 'number') {
      return text;
    } else if (typeof text !== 'string') {
      return '';
    } else if (text === '') {
      return '';
    }

    let lines = text.split(newline);
    return lines.map(line => `<p>${line}</p>`).join("");
  }

  /**
   * Converts an html string into React elements that can be rendered in a JSX tree.
   * @param html Html string with tags
   * @returns {*} React elements (there can be many if html has no root tag)
   */
  static htmlToComponents(html) {
    const htmlToReactParser = new Parser();
    return htmlToReactParser.parse(html);
  }

  /**
   * Converts an html string into plain text and truncate the string by replacing the extraneous text by an ellipsis (...)
   * @param html Html string with tags
   * @param length Maximum length of plain text
   * @returns {string|string} Truncated string
   */
  static htmlToEllipsis(html, length) {
    const fullText = StringUtils.nullToEmpty(HtmlUtils.removeHtml(html)).trim();

    const truncatedText = fullText.length > length ?
      fullText.substring(0, length).concat("...") : StringUtils.nullToEmpty(fullText);

    return truncatedText.length > 0 ? `${truncatedText}` : "";
  }
}
