import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";
import { QUERY as QUERY_APPLICATION_ADMIN_SEARCH } from "../search/useQueryApplicationAdminSearch";
import { QUERY as QUERY_APPLICATION_DATE_GROUP_AND_COUNT } from "../view/useQueryApplicationCountByDate";
import { QUERY as QUERY_APPLICATION_COUNT } from "../view/useQueryApplicationCount";

const QUERY_SCOPE = "applicationAdmin";
const QUERY_NAME = "applicationAdminDelete";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
        $id: ID!,
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id
        ) {
          _id
        }
      }
    }
  `;

export default function useMutationApplicationDelete() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {
    refetchQueries: [QUERY_APPLICATION_ADMIN_SEARCH, QUERY_APPLICATION_DATE_GROUP_AND_COUNT, QUERY_APPLICATION_COUNT]
  });
}
