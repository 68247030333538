import React from "react";
import { useController } from "react-hook-form";
import FormControl from "react-bootstrap/FormControl";
import useTranslationCommon from "../useTranslationCommon";
import "./ValidatedInput.scss";
import StringUtils from "../../../utils/StringUtils";
import { MAX_LENGTH_EMAIL } from "../../../constants/ui";

export default function ValidatedInputEmail(props) {
  const { t, loading: tLoading } = useTranslationCommon();
  const {
    name,
    control,
    maxLength = MAX_LENGTH_EMAIL,
    defaultValue,
    required,
    ...otherProps
  } = props;

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    rules: { pattern: StringUtils.emailRegexp(), maxLength, required },
    name,
    control,
    defaultValue: StringUtils.nullToEmpty(defaultValue),
  });

  let errorMessage = "";

  if (error && !tLoading) {
    switch (error.type) {
      case "pattern":
        errorMessage = t("common:common_input_email_invalid");
        break;

      case "maxLength":
        errorMessage = t("common:common_input_length_max");
        break;

      case "required":
        errorMessage = t("common:common_input_required");
        break;

      default:
        errorMessage = error.type;
    }
  }

  return (
    <div className="ValidatedInput">
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      <FormControl
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...otherProps}
      />
    </div>
  );
}
