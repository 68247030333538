import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useQueryJobCount from "./useQueryJobCount";

export default function JobStatusCountItem(props) {
  const { status, statusLabel } = props;

  const {
    data: count,
    loading,
    errors,
  } = useQueryJobCount({ status });

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="JobStatusCountItem">
            <Row>
              <Col>
                <span>{statusLabel}</span>
              </Col>
              <Col>
                <span>{count}</span>
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
