import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import ValidatedInputText from "../../common/widgets/ValidatedInputText";
import RequiredLabel from "../../common/widgets/RequiredLabel";
import "./JobSaveLocationForm.scss";
import JobWorkModeEnum, {getJobWorkModeLabel} from "../JobWorkModeEnum";
import ValidatedInputSelect from "../../common/widgets/ValidatedInputSelect";
import ValidatedInputCheckbox from "../../common/widgets/ValidatedInputCheckbox";

export default function JobSaveLocationForm({t, loading, control, job, isLocationRequired, isCountryRequired}) {
  useShowLoader(loading);

  // Retrieve translations for work mode options (anything before loading is ready won't be actually displayed)
  const jobWorkModeChoices = React.useMemo(() => {
    if (loading)
      return [];

    return Object.values(JobWorkModeEnum).filter(mode => mode !== JobWorkModeEnum.TEMPORARY).map(workMode => ({
      value: workMode,
      label: loading ? workMode : getJobWorkModeLabel(t, workMode)
    }))},
    [t, loading]);

  // Retrieve translations for work permit options (anything before loading is ready won't be actually displayed)
  const workPermitChoices = React.useMemo(() => {
    if (loading)
      return [];

    return [{
      value: true,
      label: loading ? "" : t("jobs:employers_job_save_location_work_permit_required_label")
    }]},
    [t, loading]);

  return (
    <div className="JobSaveLocationForm">
      <Row>
        <Col className="location-invite-msg-col">
          {t("jobs:employers_job_save_location_invite_msg")}
        </Col>
      </Row>
      <Row>
        <Col className="input-col-container">
          <Row>
            <Col>
              {t("jobs:employers_job_save_location_work_mode_label")}
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidatedInputSelect
                name={"workMode"}
                control={control}
                choices={jobWorkModeChoices}
                withBlankOption
                defaultValue={job.workMode === JobWorkModeEnum.TEMPORARY ? "" : job.workMode}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {t("jobs:employers_job_save_location_city_label")}
              {isLocationRequired && <RequiredLabel/>}
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidatedInputText
                name="city"
                type="text"
                required={isLocationRequired}
                control={control}
                defaultValue={job.city}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {t("jobs:employers_job_save_location_state_label")}
              {isLocationRequired && <RequiredLabel/>}
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidatedInputText
                name="state"
                required={isLocationRequired}
                type="text"
                control={control}
                defaultValue={job.state}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {t("jobs:employers_job_save_location_country_label")}
              {(isLocationRequired || isCountryRequired) && <RequiredLabel/>}
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidatedInputText
                name="country"
                required={isLocationRequired || isCountryRequired}
                type="text"
                control={control}
                defaultValue={job.country}
              />
            </Col>
          </Row>
          <Row>
            <Col className="input-col-container">
              <Row>
                <Col>
                  <ValidatedInputCheckbox
                    name={"isWorkPermitRequired"}
                    control={control}
                    choices={workPermitChoices}
                    defaultValue={job.isWorkPermitRequired}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
