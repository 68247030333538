import React, {useCallback} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import {Link} from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import RecruiterCountItem from "./RecruiterCountItem";
import RecruiterCountByDateItem from "./RecruiterCountByDateItem";
import EmployerCountItem from "./EmployerCountItem";
import EmployerCountByDateItem from "./EmployerCountByDateItem";
import ApplicationCountItem from "./ApplicationCountItem";
import ApplicationCountByDateItem from "./ApplicationCountByDateItem";
import RefreshButton from "../../common/widgets/RefreshButton";
import "./AdminBlock.scss";
import ActionDiv from "../../common/widgets/ActionDiv";
import JobPublicationCountByDateItem from "./JobPublicationCountByDateItem";
import JobCountItem from "./JobCountItem";
import useReferrer from "../../infra-no-ui/navigation/useReferrer";

function AdminBlock(/*props*/) {

  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "AdminBlock");

  const {redirectTo, withCurrentAsReferrer} = useReferrer();

  const goToAllRecruiters = useCallback(() => {
    redirectTo(ROUTES.EMPLOYER_ADMIN_RECRUITERS);
  }, [redirectTo]);

  const goToAllApplications = useCallback(() => {
    redirectTo(ROUTES.EMPLOYER_ADMIN_APPLICATIONS);
  }, [redirectTo]);

  const goToAllEmployers = useCallback(() => {
    redirectTo(ROUTES.EMPLOYER_ADMIN_EMPLOYERS);
  }, [redirectTo]);

  const goToAllJobs = useCallback(() => {
    redirectTo(ROUTES.EMPLOYER_ADMIN_JOBS);
  }, [redirectTo]);

  return (
    <div className="AdminBlock">
      <Row>
        <Col>
          <h1>{t("admin:admin_dashboard_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <RefreshButton />
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <Row>
            <Col>
              <Link to={withCurrentAsReferrer(ROUTES.EMPLOYER_ADMIN_PAYMENT)}>
                {t("admin:admin_dashboard_payment_link")}
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to={withCurrentAsReferrer(ROUTES.EMPLOYER_ADMIN_APPLICATION_COUNT_BY_JOB)}>
                {t("admin:admin_dashboard_application_count_by_job_link")}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <ActionDiv className="inflate" onClick={() => goToAllRecruiters()}><RecruiterCountItem /></ActionDiv>
        </Col>
        <Col className="widget-block">
          <RecruiterCountByDateItem />
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <ActionDiv className="inflate" onClick={() => goToAllEmployers()}><EmployerCountItem /></ActionDiv>
        </Col>
        <Col className="widget-block">
          <EmployerCountByDateItem />
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <ActionDiv className="inflate" onClick={() => goToAllApplications()}><ApplicationCountItem /></ActionDiv>
        </Col>
        <Col className="widget-block">
          <ApplicationCountByDateItem />
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <ActionDiv className="inflate" onClick={() => goToAllJobs()}><JobCountItem /></ActionDiv>
        </Col>
        <Col className="widget-block">
          <JobPublicationCountByDateItem />
        </Col>
      </Row>
    </div>
  );
}

export default AdminBlock;
