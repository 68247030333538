import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";
import {JOB_TYPE_ALL_FIELDS_FRAGMENT} from "../view/useQueryJobById";
import {QUERY as QUERY_JOB_COUNTS_BY_STATUS} from "../search/useQueryJobCountsByStatus";
import useLanguages from "../../infra-no-ui/translation/useLanguages";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobUpdate";

export const QUERY = gql`
    ${JOB_TYPE_ALL_FIELDS_FRAGMENT}
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
        $id: ID!,
        $streetAddress: String,
        $postalCode: String,
        $city:String,
        $state: String,
        $country: String,
        $dateUnpublished: DateTime,
        $employmentType: JobEmploymentTypeEnum,
        $baseSalaryValue: Int,
        $baseSalaryUnitText: JobSalaryUnitTextEnum,
        $seniority: String
        $commission: Int,
        $employerNote: String,
        $technicalSkills: [String],
        $softSkills: [String],
        $workMode: JobWorkModeEnum,
        $isWorkPermitRequired: Boolean,
        $lastSavedStep: JobCreationStepEnum, 
        $lang: GlobalLanguageEnum!
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id,
          streetAddress: $streetAddress,
          postalCode: $postalCode,
          city: $city,
          state: $state,
          country: $country,
          dateUnpublished: $dateUnpublished,
          employmentType: $employmentType,
          baseSalaryValue: $baseSalaryValue,
          baseSalaryUnitText: $baseSalaryUnitText,
          seniority: $seniority,
          commission: $commission,
          employerNote: $employerNote,
          technicalSkills: $technicalSkills,
          softSkills: $softSkills,
          workMode: $workMode,
          isWorkPermitRequired: $isWorkPermitRequired,
          lastSavedStep: $lastSavedStep
        ) {
          ...JobTypeAllFieldsFragment
        }
      }
    }
  `;

export default function useMutationJobUpdate() {
  const {getCurrentLanguageCode} = useLanguages();
  const variables = {lang: getCurrentLanguageCode().toUpperCase()};

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    refetchQueries: [QUERY_JOB_COUNTS_BY_STATUS]
  });
}
