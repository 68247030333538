import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "jobAdmin";
const QUERY_NAME = "jobAdminMarkPublished";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
        $id: ID!,
        $published: Boolean
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id,
          published: $published
        ) {
          _id
          status
          datePosted
        }
      }
    }
  `;

export default function useMutationJobMarkPublished() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
