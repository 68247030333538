import React from "react";
import JobDashboardApplicationsLineChart from "./JobDashboardApplicationsLineChart";
import useQueryDateByValueSearchCount from "../search/useQueryDateGroupAndCount";
import WaitForData from "../../common/data-fetching/WaitForData";
import useTranslationJobDashboard from "../useTranslationJobDashboard";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import "./JobStatistics.scss";
import PageSubtitle from "../../common/widgets/PageSubtitle";

export default function JobStatistics(props) {
  const { job } = props;

  const {
    data: candidatesByDateCounts,
    loading: candidatesByDateLoading,
    errors: candidatesByDateErrors,
  } = useQueryDateByValueSearchCount(job._id);

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading, "JobDashboardCommissionBlock");
  const graphicTitle = t("job-dashboard:job_dashboard_graphic_title");

  return (
    <WaitForData
      loading={candidatesByDateLoading}
      error={candidatesByDateErrors}
      onLoaded={() => (
        <div className="JobStatistics">
          <PageSubtitle colTitle={graphicTitle} />
          <JobDashboardApplicationsLineChart
            data={candidatesByDateCounts}
            createdAt={job.createdAt}
            dateUnpublished={job.dateUnpublished}
            status={job.status}
          />
        </div>
      )}
    />
  );
}
