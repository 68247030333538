import React from "react";
import OrganizationInlineEditInput from "./OrganizationInlineEditInput";
import InlineEditInputTextArea from "../../common/widgets/InlineEditInputTextArea.js";

/**
 * Generic component for all organization editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function OrganizationInlineEditInputTextArea(props) {

  return <OrganizationInlineEditInput InlineEditInputComponent={InlineEditInputTextArea} {...props}/>
};
