import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationCandidates from "../useTranslationCandidates";
import useQueryApplicationSearch from "../search/useQueryApplicationSearch";
import Loader from "../../common/loading-widgets/Loader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ApplicationsList from "./ApplicationsList";
import useQueryJobById from "../../jobs/view/useQueryJobById.js";

export default function CandidatesList(props) {
  const { jobId, status, onChangeStatus } = props;

  const CANDIDATES_SORT_TYPE = "DATE_DESC";
  const CANDIDATES_LIST_LENGTH = 50;
  const { data: applicationSearch, loading: applicationLoading, errors: applicationErrors } = useQueryApplicationSearch(
    jobId,
    CANDIDATES_LIST_LENGTH,
    status ? { status } : {},
    CANDIDATES_SORT_TYPE
  );

  const {data: job, loading: jobLoading, errors: jobErrors} = useQueryJobById(jobId);

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ApplicationsList");

  return (
    <div className={"ApplicationsList form-with-rows"}>
      <Loader>
        <WaitForData
          loading={applicationLoading || jobLoading}
          errors={applicationErrors || jobErrors}
          onLoaded={() => (
            <>
              {applicationSearch.length === 0 && (
                <div>
                  <Row>
                    <Col>{t("candidates:candidates_no_candidates_msg")}</Col>
                  </Row>
                </div>
              )}
              {applicationSearch["nodes"].length > 0 && (
                <ApplicationsList
                  applications={applicationSearch["nodes"]}
                  status={status}
                  onChangeStatus={onChangeStatus}
                  job={job}
                />
              )}
            </>
          )}
        />
      </Loader>
    </div>
  );
}
