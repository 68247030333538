import React from "react";
import Page from "../../common/page/Page";
import useTranslationAdmin from "../useTranslationAdmin";
import PaymentBlock from "./PaymentBlock";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";


export default function PaymentPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "PaymentPage");

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="PaymentPage">
          <Page.Title text={t("admin:admin_payment_page_title")} loading={tLoading} />
          <Page.Content>
            <PaymentBlock />
          </Page.Content>
        </Page>
      )}
    />
  );
}
