import {gql} from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";
import { QUERY as QUERY_RECRUITER_FEEDBACK } from "../view/useQueryRecruiterFeedbackByRecruiterId";

const QUERY_SCOPE = "recruiterFeedback";
const QUERY_NAME = "recruiterFeedbackDelete";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} ($id: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME} (id: $id) {
          _id
        }
      }
    }
  `;

export default function useMutationRecruiterFeedbackDelete() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {
    refetchQueries: [QUERY_RECRUITER_FEEDBACK],
  });
}
