import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./ErrorModal.scss";
import StatusIcon from "../../../img/incorrect.png";
import Image from "react-bootstrap/Image";
import useTranslationCommon from "../useTranslationCommon";
import useShowLoader from "../loading-widgets/useShowLoader";
import StringUtils from "../../../utils/StringUtils.js";

/**
 * Display an error modal. Content is passed as a child. onDismiss is called when user dismisses the modal.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ErrorModal({show, onDismiss, children, className}) {

  const {t, loading: tLoading} = useTranslationCommon();
  useShowLoader(tLoading, "ErrorModal");

  // RENDER

  return (
    <Modal show={show} onHide={onDismiss} className={`ErrorModal ${StringUtils.isNullOrEmpty(className) ? "" : className}`}>
      <Modal.Body>
        <div>
          <Image src={StatusIcon} className={"status-icon"}/>
        </div>
        <div>
          {children ? children : null}
        </div>
        <div className={"button-row"}>
          <Button onClick={onDismiss}>
            {t("common:close_button")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
