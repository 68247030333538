import React from "react";
import Form from "react-bootstrap/Form";
import SuccessModal from "../common/modals/SuccessModal";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import FormText from "react-bootstrap/FormText";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import "./ResetPasswordForm.scss";
import useResetPassword from "../authentication-no-ui/useResetPassword";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import * as ROUTES from "../../constants/routes";
import { Redirect } from "react-router-dom";
import ActionButton from "../common/widgets/ActionButton";
import useReferrer from "../infra-no-ui/navigation/useReferrer";

export default function ResetPasswordForm() {
  const onSubmit = (event) => {
    event.preventDefault();
    resetPassword(email.trim());
    setShowSuccess(true);
  };

  const canSubmit = () => {
    return email !== "";
  };

  const hideSuccess = () => {
    setShowSuccess(false);

    // Redirect to HOME when done
    redirectTo(ROUTES.HOME);
  };

  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  useShowLoader(tLoading, "ResetPasswordForm");
  const authUser = useAuthUser();
  const {redirectTo} = useReferrer();

  const [email, setEmail] = React.useState("");
  const [showSuccess, setShowSuccess] = React.useState(false);
  const { resetPassword, loading, error } = useResetPassword();
  // If already logged, do not display this page, redirect to HOME
  if (authUser.authenticated) {
    return <Redirect to={ROUTES.HOME} />;
  }

  // RENDER
  return (
    <div className="ResetPasswordForm">
      <Form onSubmit={onSubmit} className={"form-with-rows"}>
        {/* The modal is visible only when form has been submitted successfully */}
        <SuccessModal
          show={showSuccess && !error && !loading}
          onDismiss={hideSuccess}
        >
          <p>{t("authentication-ui:reset_password_success_msg")}</p>
        </SuccessModal>
        <FormGroup>
          <FormControl
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder={t(
              "authentication-ui:reset_password_email_placeholder"
            )}
            type="email"
            autoComplete="email"
          />
        </FormGroup>
        <FormGroup className="last-button-group">
          <ActionButton
            loading={loading}
            disabled={!canSubmit()}
            type="submit"
          >
            {t("authentication-ui:reset_password_reset_button")}
          </ActionButton>
        </FormGroup>
        {error && (
          <FormText as="div" className="form-error">
            {error}
          </FormText>
        )}
      </Form>
    </div>
  );
}
