import { gql } from "@apollo/client";
import useWrappedQuery from "../common/data-fetching/useWrappedQuery";
import useLanguages from "../infra-no-ui/translation/useLanguages.js";

const QUERY_SCOPE = "employer";
const QUERY_NAME = "employerByEmail";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($email: String!, $lang: GlobalLanguageEnum!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(email: $email) {
        _id
        firstName
        lastName
        phone
        email
        organization {
          _id
          company
          companyPresentation
          isGptEnabled
          companyIntroFr
          companyIntroEn
          paymentTerms(lang: $lang)
        }
        defaultLanguage
      }
    }
  }
`;

export default function useQueryEmployerByEmail(email) {

  const {getCurrentLanguageCode} = useLanguages();

  const variables = {
    email: email,
    lang: getCurrentLanguageCode().toUpperCase()
  };

  // If email is null, skip query and return a null profile
  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, { variables, skip: !email });
}
