import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useQueryApplicationCount from "../view/useQueryApplicationCount";

export default function ApplicationByJobCountItem(props) {
  const { jobId } = props;
  const { data: count, loading, errors } = useQueryApplicationCount({jobId});

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <Row>
            <Col>
              <span>{count}</span>
            </Col>
          </Row>
        )}
      />
    </HorizontalLoader>
  );
}
