import React from "react";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useContentModal from "../../common/modals/useContentModal";
import SimilarJobs from "./SimilarJobs"
import ActionLink from "../../common/widgets/ActionLink";
import StringUtils from "../../../utils/StringUtils";

export default function JobSimilarJobsCommissionTip(props) {
  const { similarJobs } =
    props;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobSimilarJobsCommissionTip");

  // Remove invalid jobs
  const cleanedSimilarJobs = similarJobs.filter(item => item.employer && !StringUtils.isNullOrEmpty(item.employer.organization.company));

  // Get unique company names
  const employers = [
    ...new Set(cleanedSimilarJobs.map((item) => item.employer.company)),
  ];

  // Modal to show similar jobs
  const {
    ContentModal: SimilarJobsModal,
    show: showSimilarJobsModal,
    hide: hideSimilarJobsModal,
  } = useContentModal("SimilarJobs");

  const onClickSimilarJobs = () => {
    showSimilarJobsModal(getSimilarJobs());
  };

  const getSimilarJobs = () => {
    return (
      <SimilarJobs
        onClose={hideSimilarJobsModal}
        jobs={cleanedSimilarJobs}
      />
    );
  };

  const similarJobsLink = (
    <ActionLink onClick={() => onClickSimilarJobs()}>{t("jobs:employers_job_save_tip_similar_job_msg", { count: StringUtils.nullToZero(employers.length) })}</ActionLink>
  );
  const similarJobsMsg = t("jobs:employers_job_save_tip_msg1", {
    postProcess: "reactPostprocessor",
    similarJobsLink: similarJobsLink,
  });

  return (
    <>
      {SimilarJobsModal}
      <>
        {
          employers.length > 0 && (
            similarJobsMsg
          )
        }
      </>
    </>
  );
}
