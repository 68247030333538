
import useModuleTranslation from "../infra-no-ui/translation/useModuleTranslation";
import React from "react";

/**
 * Dynamically load translation files pertaining to the job dashboard module.
 * @return {*}
 */
export default function useTranslationJobDashboard() {

  // Imports must be memoized so that they don't change on every render
  const imports = React.useMemo(
    () => ({
      en: import("./locales/en/job-dashboard.json"),
      fr: import("./locales/fr/job-dashboard.json"),
    }),
    []
  );

  return useModuleTranslation("job-dashboard", imports);
}
