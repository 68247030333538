import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import EditableTags from "./EditableTags";
import "./JobSaveSkillsForm.scss";

export default function JobSaveSkillsForm({t, loading, isStepSaved, softSkills, techSkills, onChangeSoftSkills, onChangeTechSkills}) {

  useShowLoader(loading);

  // The prompts are not the same whether user has previously selected tags
  const inviteMsg = isStepSaved
    ? t("jobs:employers_job_save_skills_modify_msg")
    : t("jobs:employers_job_save_skills_suggestion_msg");

  return (
    <div className="JobSaveSkillsForm">
      <Row>
        <Col className="invite-msg-col">{inviteMsg}</Col>
      </Row>
      <Row>
        <Col className="skills-col-container">
          <Row>
            <Col className={"label"}>
              {t("jobs:employers_job_save_skills_technical_label")}
            </Col>
          </Row>
          <Row>
            <Col>
              <EditableTags
                tags={techSkills}
                onChangeTags={onChangeTechSkills}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col className={"label"}>
              {t("jobs:employers_job_save_skills_soft_label")}
            </Col>
          </Row>
          <Row>
            <Col>
              <EditableTags
                tags={softSkills}
                onChangeTags={onChangeSoftSkills}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
