import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import useQueryRecruiterCount from "./useQueryRecruiterCount";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageSubtitle from "../../common/widgets/PageSubtitle";
import "./RecruiterCountItem.scss";

export default function RecruiterCountItem(props) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "RecruiterCountItem");

  const { data: count, loading, errors } = useQueryRecruiterCount();

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="RecruiterCountItem">
            <PageSubtitle
              colTitle={t("admin:admin_dashboard_recruiters_count_title")}
            />
            <Row>
              <Col className="recruiter-count">
                <span>{count}</span>
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
