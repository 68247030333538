import React from "react";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../utils/StringUtils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./JobMaxCommissionTip.scss"


export default function JobMaxCommissionTip(props) {
  const { jobGlobalMaxCommission, similarJobs } = props;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobMaxCommissionTip");

  // Remove invalid jobs
  const cleanedSimilarJobs = similarJobs.filter(
    (item) => item.employer && !StringUtils.isNullOrEmpty(item.employer.company)
  );

  // If there are similar jobs, show the max commission of similar jobs, otherwise show global max commission
  const commissions = cleanedSimilarJobs.map((item) => item.commission);
  const jobMaxCommission =
    cleanedSimilarJobs.length > 0
      ? Math.max(...commissions)
      : jobGlobalMaxCommission;

  return (
    <>
      <Row className = "JobMaxCommissionTip">
        <Col className = "count-label-col">{t("jobs:employers_job_save_tip_highest")}</Col>
        <Col className = "count-col">
          {t("jobs:employers_job_dollars",{amount:jobMaxCommission})}
        </Col>
      </Row>
    </>
  );
}
