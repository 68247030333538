import React from "react";
import ActionLink from "../../common/widgets/ActionLink.js";
import "./StandardPromptWidget.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function StandardPromptWidget({onSelect, template}) {

  return (
    <div className="StandardPromptWidget">
      <Row className={"prompt-row"}>
        <Col className={"bullet-col"}>
          {">"}
        </Col>
        <Col>
          <ActionLink onClick={() => onSelect()}>{template}</ActionLink>
        </Col>
      </Row>
    </div>
  );
};
