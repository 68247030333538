import React from "react";
import * as ROUTES from "../../constants/routes";
import Image from "react-bootstrap/Image";
import logo from "../../img/logo-spotterful-employers.png";
import {Link} from "react-router-dom";
import "./MenuLogo.scss";
import useReferrer from "../infra-no-ui/navigation/useReferrer";

export default function MenuLogo(/*props*/) {

  const {withCurrentAsReferrer} = useReferrer();

  return (
    <div className="MenuLogo">
      <Link to={withCurrentAsReferrer(ROUTES.HOME)}>
        <Image src={logo} className="logo" />
      </Link>
    </div>
  );
};
