import {gql} from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "employer";
const QUERY_NAME = "employerUpdate";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
      $id: ID!,
      $defaultLanguage: EmployerLanguageEnum
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id,
          defaultLanguage: $defaultLanguage
        ) {
          _id
          defaultLanguage
        }
      }
    }
  `;

export default function useMutationProfileUpdateLanguage() {

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
