import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "application";
const QUERY_NAME = "applicationSearchApproved";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($jobId: ID!, $pageSize: Int, $filter: ApplicationFilterInput!, $sort: ApplicationSortEnum) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(jobId: $jobId, pageSize: $pageSize, filter: $filter, sort: $sort) {
        nodes {
          _id
          candidateId
          candidate {
            name
            phone
            email
            linkedin
            indeed
          }
          recruiterId
          resumeId
          recruiter {
            firstName
            lastName
            phone
            email
            aboutMe
            isActiveAccount
          }
          hire {
            hiringDate,
            dateCancelled
          }
          recruiterNote
          employerNote
          status
          viewed
          createdAt
        }
      }
    }
  }
`;

export default function useQueryApplicationSearch(jobId, pageSize, filter, sort) {
  const variables = {jobId, pageSize, filter, sort};
  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
