import React from "react";
import Loader from "../../common/loading-widgets/Loader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import useQueryRecruiterFeedbackByRecruiterId from "./useQueryRecruiterFeedbackByRecruiterId";
import useTranslationRecruiters from "../useTranslationRecruiters";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import ReactStars from "react-rating-stars-component";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./RecruiterFeedback.scss";
import RecruiterFeedbackLink from "./RecruiterFeedbackLink";
import HtmlUtils from "../../../utils/HtmlUtils";
import useAuthUser from "../../authentication-no-ui/useAuthUser";
import TypeUtils from "../../../utils/TypeUtils";

export default function RecruiterFeedback(props) {
  const { application } = props;

  const { profile } = useAuthUser();
  const employerId = profile._id;

  const recruiterId = application.recruiterId;

  const { t, loading: tLoading } = useTranslationRecruiters();
  useShowLoader(tLoading, "RecruiterProfile");

  const { data, loading, errors } =
    useQueryRecruiterFeedbackByRecruiterId(recruiterId);

  const ratingAverage = !TypeUtils.arrayIsEmpty(data)
    ? data.reduce((accumulator, current) => accumulator + current.rating, 0) /
      data.length
    : 0;

  return (
    <Loader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="RecruiterFeedback">
            <Row className={"label-row"}>
              <Col>{t("recruiters:recruiters_feedback_label")}</Col>
            </Row>
            <Row>
              <Col>
                <ReactStars
                  edit={false}
                  count={5}
                  value={ratingAverage}
                  size={24}
                />
              </Col>
            </Row>
            {data.map((item) => (
              <Row key={item._id}>
                <Col>
                  <p className="feedback-paragraph">
                    {HtmlUtils.nlToBr(item.feedback)}
                  </p>
                </Col>
              </Row>
            ))}
            <Row>
              <Col>
                <RecruiterFeedbackLink
                  application={application}
                  employerId={employerId}
                  comments={data}
                />
              </Col>
            </Row>
          </div>
        )}
      />
    </Loader>
  );
}
