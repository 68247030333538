import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "employer";
const QUERY_NAME = "employerUpdate";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
      $id: ID!,
      $firstName: String
      $lastName: String
      $phone: String
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id,
          firstName: $firstName,
          lastName: $lastName,
          phone: $phone,
        ) {
          _id
          firstName
          lastName
          phone
          email
        }
      }
    }
  `;

export default function useMutationProfileUpdate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
