import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";
import { QUERY as QUERY_STATUS_COUNT } from "../search/useQueryStatusGroupAndCount";
import { QUERY as QUERY_APPLICATION_SEARCH } from "../search/useQueryApplicationSearch";

const QUERY_SCOPE = "application";
const QUERY_NAME = "applicationUpdateEmployerData";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
      $id: ID!,
      $employerNote: String
      $viewed: Boolean
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id,
          employerNote: $employerNote,
          viewed: $viewed
        ) {
          _id
          employerNote
          viewed  
          resumeId
        }
      }
    }
  `;

export default function useMutationApplicationUpdate() {

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {
    refetchQueries: [QUERY_STATUS_COUNT, QUERY_APPLICATION_SEARCH]
  });
}
