import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useQueryJobAlertsSearch from "../search/useQueryJobAlertsSearch";
import WaitForData from "../../common/data-fetching/WaitForData";
import DashboardAlertsListItems from "./JobDashboardAlertsListItems";
import Loader from "../../common/loading-widgets/Loader";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobDashboard from "../useTranslationJobDashboard";
import "./JobDashboardAlertsBlock.scss";

export default function JobDashboardAlertsBlock(props) {
  const { job } = props;
  const ALERTS_LIST_LENGTH = 10;
  const ALERTS_LIST_STATUS = "NOT_VIEWED";
  const ALERTS_LIST_SORT_TYPE = "DATE_ASC";

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading, "JobDashboardAlertsBlock");

  const { data, loading, errors } = useQueryJobAlertsSearch(
    ALERTS_LIST_LENGTH,
    { jobId: job._id, status: ALERTS_LIST_STATUS },
    ALERTS_LIST_SORT_TYPE
  );

  const blockTitle = t("job-dashboard:job_dashboard_alerts_list_label");

  return (
    <>
      <Loader>
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            data["nodes"].length > 0 && (
              <div className="DashboardAlertsList widget-block">
                <Row>
                  <Col className={"title-col"}>
                    <h2>{blockTitle}</h2>
                  </Col>
                </Row>
                <DashboardAlertsListItems items={data["nodes"]} />
              </div>
            )
          )}
        />
      </Loader>
    </>
  );
}
